<template>
    <div class="container">
      <div class="main-content">
        <div class="avatar-container">
          <img class="avatar" src="../assets/logo.png" alt="用户头像" />
        </div>
        <div class="invitation-text">
          <h1>佛曰</h1>
          <p>阿弥陀佛，在这纷扰尘世中，以平和之心倾听你的每一个疑问。如同晨钟暮鼓，我的话语旨在提醒，引导，让您在忙碌生活中也能寻到片刻的宁静与明灯。无论是生活小事的困惑，还是心灵深处的迷茫，我都在此，愿为您把脉问诊，共同体味生活的甘甜与苦涩，悟出人生真谛。</p>
        </div>
        <button class="action-button" @click="handleButtonClick">前往 佛曰 解惑</button>
      </div>
    </div>
  </template>
  
  <script>  
  export default {
    name: 'BuddhaAI', 
    setup() {
      const handleButtonClick = () => {
        const appScheme = 'https://xjshtech.app/BuddhaAI?id=shareNew';
        const appStoreUrl = 'https://apps.apple.com/app/id-your-app-id';
        window.location = appScheme;
  
        setTimeout(() => {
          if (!document.hidden) {
            window.location = appStoreUrl;
          }
        }, 2500);
      };
  
      return {
        handleButtonClick
      };
    }
  };
  </script>
  
  <style scoped>

  body, html {
    height: 100%;
    margin: 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    height: 100vh; /* 视口高度 */
    text-align: center; /* 文本居中 */
    background-color: #f0f0f0;
  }
  
  .main-content {
    text-align: center;
    padding: 20px;
  }
  
  .avatar-container {
    padding: 20px;
  }
  
  .avatar {
    width: 120px; /* 根据设计稿调整 */
    height: 120px; /* 根据设计稿调整 */
    border-radius: 50%; /* 如果头像是圆形的 */
    margin: auto;
  }
  
  .invitation-text h1 {
    margin: 20px 0;
    font-size: 24px; /* 根据设计稿调整 */
  }
  
  .invitation-text p {
    margin: 20px 0;
    color: #666; /* 根据设计稿调整 */
    font-size: 16px; /* 根据设计稿调整 */
    line-height: 1.6; /* 增加行间距，根据需要调整 */
  }
  
  .action-button {
    background-color: #142C46; /* 根据设计稿调整 */
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 5px; /* 根据设计稿调整 */
    font-size: 18px; /* 根据设计稿调整 */
    cursor: pointer;
    margin-top: 20px;
  }
  
  .action-button:hover {
    background-color: #142C46; /* 按钮悬停效果 */
  }
  
  .footer {
    /* 根据设计调整 */
  }
  </style>